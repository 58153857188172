/* eslint-disable max-len */
import 'src/pages/check-out/index.scss';
import { ACCOUNT_TYPE, BILLING_FREQUENCY, FILTERED_PLANS, PAGES, PAYMENT, PAYMENT_METHODS, STATE_OPTIONS } from 'src/constants';
import React, { useEffect, useState } from 'react';
import { useApi, useApiState, useCart, useConfig, useFormData, usePaymentJs } from 'src/hooks';
import BillingInformationValidation from 'src/form-validations/billing-information';
import Checkbox from 'src/components/atoms/checkbox';
import Form from 'src/components/atoms/form';
import Icon from 'src/components/atoms/icon';
import Input from 'src/components/atoms/input';
import Link from 'src/components/atoms/link';
import PaymentInformationValidation from 'src/form-validations/payment-information';
import Radio from 'src/components/atoms/radio';
import SEO from 'src/components/atoms/seo';
import ServiceDetailsValidation from 'src/form-validations/service-details';
import { navigate } from 'gatsby';

import { Plan } from 'src/helpers/transformers/api/_types';

export const DATA_LAYER_NAME = 'adobeDataLayer';

const { CHECKING, SAVINGS } = ACCOUNT_TYPE;
const { ANNUAL, MONTHLY } = BILLING_FREQUENCY;
const { CONFIRMATION, HOME } = PAGES;
const { BANK_ACCOUNT, CREDIT_CARD } = PAYMENT_METHODS;

// eslint-disable-next-line sort-keys
const SECTIONS = { SERVICE: 0, BILLING: 1, PAYMENT: 2 };

const Section = ({ canEdit, children, contentText, title, isOpen, onEdit }: any) => {
  const { overrideStyles } = useConfig();

  return (
    <div className='section'>
      <div className='section--header'>
        <span className={`section--header--title ${isOpen ? 'open' : ''}`}>{title}</span>
        {canEdit && <span className={`section--header--button ${overrideStyles?.hyperLinkClass} ${isOpen ? 'hidden' : ''}`} role='button' onClick={() => onEdit()}>Edit</span>}
      </div>
      <div className={`section--content ${!isOpen ? 'hidden' : ''}`}>
        {contentText && <div className='section--content--text'>{contentText}</div>}
        <div className='section--content--form'>
          {children}
        </div>
      </div>
    </div>
  );
};

const TermsAndConditions = ({ bankAccountType, paymentMethod, paymentTerms, termsAndConditions }: any) => {
  const method = paymentMethod === BANK_ACCOUNT ? bankAccountType : paymentMethod;

  return (
    <div className='section--content--form--terms'>
      <Checkbox name='termDefault' label='I agree to the '>
        <a href={termsAndConditions} target='_blank' rel='noreferrer'>Terms and Conditions</a>
      </Checkbox>

      {paymentTerms[method]?.map((term: string, index: number) => (
        <Checkbox key={term} name={`term${index}`} label={term} value={term} />
      ))}
    </div>
  );
};

const push = (event: any) => (window as any)?.[DATA_LAYER_NAME]?.push?.(event);
const getBrand = () => location.hostname?.split('.')?.reverse()?.[1] ?? '';

const pushCheckOut = (product: any) => push({
  event: 'checkOut',
  global: {
    brand: getBrand(),
    eventAction: 'checkOut'
  },
  product: product
});

const getPlansInCart = (plansInCart:any) => {
  const product: any = [];
  if (sessionStorage.getItem(FILTERED_PLANS.PLANS)) {
    const filteredPlans = JSON.parse(sessionStorage.getItem(FILTERED_PLANS.PLANS) || '');
    // eslint-disable-next-line @typescript-eslint/no-inferrable-types
    plansInCart.forEach((plan: Plan) => {
      const planQuantity = plan.quantity ? plan.quantity : 0;
      const filteredPlan = filteredPlans?.find((p: any) => p.id === plan.id);
      product.push({
        cancelReturnFee: 0.00,
        category: 'ANCILLARY SERVICE',
        discount: plan.discountPrice ? Number((plan.price.monthlyAmount - plan.discountPrice.monthlyDiscountAmount).toFixed(2)) : 0,
        flags: plan.promotion,
        gridPosition: filteredPlan?.featuredPosition ? filteredPlan.featuredPosition : 1,
        name: plan.name,
        planRateType: 'fixed',
        price: plan.price.monthlyAmount,
        priceTotal: plan.price.monthlyAmount,
        quantity: planQuantity,
        sku: plan.id,
        term: 12,
        type: plan.category
      });
    });
  }

  pushCheckOut(product);
};

export default () => {
  const [ openedSection, setOpenedSection ] = useState(SECTIONS.SERVICE);
  const { addBillingAddress, addServiceAddress, purchase } = useApi();
  const { billingFrequency, cart, enableDiscountForm, isCartEmpty, plansInCart, setBillingFrequency } = useCart() as any;
  const { updateFormData } = useFormData();
  const { paymentTerms, zipcode } = useApiState() as any;
  const { termsAndConditions, checkOutPageDisclaimer, overrideStyles } = useConfig();

  useEffect(() => {
    isCartEmpty && navigate(HOME);
    if(plansInCart?.length > 0) {
      getPlansInCart(plansInCart);
    }
  }, [ cart ]);

  const ServiceDetailsSection = () => {
    const defaultValues = { zipcode };

    const onComplete = () => setOpenedSection(SECTIONS.BILLING);
    const onRetry = (data: any) => updateFormData({ data, id: 'SERVICE_DETAILS_FORM' });

    return (
      <Section
        contentText='Please enter your information and service address below.'
        title='Step 1: Service details'
        isOpen={openedSection === SECTIONS.SERVICE}
        canEdit={openedSection > SECTIONS.SERVICE}
        onEdit={() => setOpenedSection(SECTIONS.SERVICE)}
      >
        <Form
          id='SERVICE_DETAILS_FORM'
          buttonLabel='Continue to billing'
          defaultValues={defaultValues}
          validation={ServiceDetailsValidation}
          onSubmit={(data: any) => addServiceAddress(data, onComplete, onRetry)}
        >
          <div className='section--content--form--group'>
            <Input name='firstName' label='First name' type='text' />
            <Input name='lastName' label='Last name' type='text' />
          </div>
          <div className='section--content--form--group'>
            <Input name='phone' label='Phone' type='tel' maxLength={10} />
            <Input name='email' label='Email' type='email' />
          </div>
          <Input name='address1' label='Service address line 1' type='text' />
          <Input name='address2' label='Apt. / unit #' type='text' secondaryLabel='Optional' />
          <Input name='city' label='City' type='text' />
          <div className='section--content--form--group'>
            <Input disabled name='zipcode' label='Zip code' type='text' inputMode='numeric' />
            <Input name='state' label='State' type='select' options={STATE_OPTIONS} />
          </div>
        </Form>
      </Section>
    );
  };

  const BillingInformationSection = () => {
    const [ showForm, setShowForm ] = useState(false);

    const onComplete = () => setOpenedSection(SECTIONS.PAYMENT);
    const onRetry = (data: any) => updateFormData({ data, id: 'BILLING_INFORMATION_FORM' });
    const onSubmit = (data: any) => addBillingAddress(data, onComplete, onRetry);

    const isOpen = openedSection === SECTIONS.BILLING;

    useEffect(() => {
      enableDiscountForm(isOpen);
    }, [ isOpen ]);

    return (
      <Section
        title='Step 2: Billing information'
        isOpen={openedSection === SECTIONS.BILLING}
        canEdit={openedSection > SECTIONS.BILLING}
        onEdit={() => setOpenedSection(SECTIONS.BILLING)}
      >
        <>
          <Form id='BILLING_INFORMATION_FORM' buttonLabel='Continue to payment' validation={BillingInformationValidation} onSubmit={onSubmit} >
            <Checkbox
              checked={true}
              label='Billing address is the same as Service address'
              name='isSameAddress'
              onClick={() => setShowForm(!showForm)}
              value='isSameAddress'
            />

            {showForm
              ? <>
                <Input name='address1' label='Billing Address Line 1' type='text' />
                <Input name='address2' label='Apt. / unit #' type='text' secondaryLabel='Optional' />
                <Input name='city' label='City' type='text' />
                <div className='section--form--group'>
                  <Input name='zipcode' label='Zip code' type='text' inputMode='numeric' />
                  <Input name='state' label='State' type='select' options={STATE_OPTIONS} />
                </div>
              </>
              : <></>
            }
            <label id='billingFrequency' className='section--content--form--radio--label'>Billing frequency</label>
            <div className='section--content--form--radio--group' role='group'>
              <Radio label='Monthly' name='billingFrequency' value={MONTHLY} onClick={setBillingFrequency} checked={billingFrequency === MONTHLY} />
              <Radio label='Annually' name='billingFrequency' value={ANNUAL} onClick={setBillingFrequency} checked={billingFrequency === ANNUAL} />
            </div>
          </Form>
        </>
      </Section>
    );
  };

  const PaymentInformationSection = () => {
    const [ paymentMethod, setPaymentMethod ] = useState(CREDIT_CARD);
    const [ bankAccountType, setBankAccountType ] = useState(CHECKING);
    const { _links } = useApiState() as any;
    if(_links?.purchase?.body?._link?.plans){
      sessionStorage.setItem(FILTERED_PLANS.LINK_PLANS, JSON.stringify(_links.purchase.body._link.plans));
    }

    useEffect(() => {
      sessionStorage.setItem(PAYMENT.METHOD, 'Credit card');
      if (paymentMethod === 'bank-account') {
        sessionStorage.setItem(PAYMENT.METHOD, 'Bank');
      }
    }, [ paymentMethod ]);

    const onComplete = () => navigate(CONFIRMATION);
    const onSubmit = (data: any, onFailure: any) => purchase(data, onComplete, onFailure);

    const isOpen = openedSection === SECTIONS.PAYMENT;

    const {
      paymentJsError, initializePaymentJs, isPaymentJsDisabled, onPaymentJsSubmit, ...paymentJsSelectors
    } = usePaymentJs({ onSubmit });

    useEffect(() => {
      isOpen && paymentMethod === CREDIT_CARD && initializePaymentJs();
      isOpen && paymentMethod === BANK_ACCOUNT && setBankAccountType(CHECKING);
    }, [ isOpen, paymentMethod ]);

    const CreditCardForm = () => (
      <Form
        buttonLabel='Confirm purchase'
        onSubmit={onPaymentJsSubmit}
        validation={PaymentInformationValidation}
        externalError={paymentJsError}
      >
        <Input label='Name on card' {...paymentJsSelectors.data_cc_name} disabled={isPaymentJsDisabled} />
        <Input label='Card number' {...paymentJsSelectors.data_cc_card} disabled={isPaymentJsDisabled} />

        <div className='section--content--form--group'>
          <Input label='Expiration date' {...paymentJsSelectors.data_cc_exp} disabled={isPaymentJsDisabled} />
          <Input label='CVV' {...paymentJsSelectors.data_cc_cvv} disabled={isPaymentJsDisabled} />
        </div>

        {TermsAndConditions({ bankAccountType, paymentMethod, paymentTerms, termsAndConditions })}
      </Form>
    );

    const BankAccountForm = () => (
      <Form
        buttonLabel='Confirm purchase'
        onSubmit={onSubmit}
        validation={PaymentInformationValidation}
      >
        <Input name='routingNumber' label='Routing Number' type='text' inputMode='numeric' maxLength={9} />
        <Input name='accountNumber' label='Account number' type='text' inputMode='numeric' maxLength={17} />
        <Input name='confirmAccountNumber' label='Confirm account number' type='text' inputMode='numeric' maxLength={17} />

        <label className='section--content--form--radio--label'>Account Type</label>
        <div className='section--content--form--radio--group' role='group'>
          <Radio label='Checking' name='accountType' value={CHECKING} onClick={setBankAccountType} checked />
          <Radio label='Savings' name='accountType' value={SAVINGS} onClick={setBankAccountType} />
        </div>

        {TermsAndConditions({ bankAccountType, paymentMethod, paymentTerms, termsAndConditions })}
      </Form>
    );

    return (
      <Section
        title='Step 3: Payment information'
        isOpen={isOpen}
        canEdit={false}
      >
        <label className='section--content--form--radio--label'>Payment method</label>
        <div className='section--content--form--radio--group'>
          <Radio label='Credit card' name='payment-group' value={CREDIT_CARD} onClick={setPaymentMethod} checked />
          <Radio label='Bank account' name='payment-group' value={BANK_ACCOUNT} onClick={setPaymentMethod} />
        </div>

        {isOpen && paymentMethod === CREDIT_CARD ? CreditCardForm() : <></>}
        {isOpen && paymentMethod === BANK_ACCOUNT ? BankAccountForm() : <></>}
      </Section>
    );
  };

  const InformationDisclaimer = () => {
    const [ showInformationDisclaimer, setShowInformationDisclaimer ] = useState(true);

    return (showInformationDisclaimer && checkOutPageDisclaimer
      ? <div className='check-out--information-disclaimer'>
        <span>{checkOutPageDisclaimer}</span>
        <span className='check-out--information-disclaimer--close'><Icon type='close' className='check-out--information-disclaimer--close--icon' onClick={() => setShowInformationDisclaimer(false)} /></span>
      </div>
      : <></>
    );
  };

  return (
    <div className='check-out'>
      <div className='check-out--nav'>
        <Icon type='arrowBack' height='11' />
        <Link className={`check-out--nav--link ${overrideStyles?.hyperLinkClass}`} label='Back to home protection plans' onClick={() => navigate(-1)} />
      </div>
      {InformationDisclaimer()}
      {ServiceDetailsSection()}
      {BillingInformationSection()}
      {PaymentInformationSection()}
    </div>
  );
};

export const Head = () => <SEO pageTitle='Checkout' />;
